<template>
    <div class="topbar">
  <!-- List of links to home, privacy policy, Sheets-macros -->
  <ul>
    <li><router-link class="link-item" to="/">Home</router-link></li>
    <li><router-link class="link-item" to="/privacy-policy">Privacy Policy</router-link></li>
    <li><router-link class="link-item" to="/sheets-macros">Sheets Macros</router-link></li>
  </ul>
  </div>
</template>

<script>
export default {

}
</script>


<style>
.topbar {
    background-color: #333;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.topbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
    /* Flex horizontal */
    display: flex;
    flex-direction: row;
    }

.link-item {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

</style>