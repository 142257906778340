import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomePage.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sheets-macros/privacy',
    name: 'sheets-macros-privacy',
    component: () => import('../views/PrivacyPolicySheets.vue'),
  },
  { 
    path: '/sheets-macros/terms',
    name: 'sheets-macros-terms',
    component: () => import('../views/SheetsTerms.vue'),
  },
  {
    path: '/sheets-macros',
    name: 'sheets-macros',
    component: () => import('../views/SheetsOverview.vue'),
  },

 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
